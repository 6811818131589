import axios from 'axios'
import type {
  FulfillmentType,
  FloatingFulfillmentPut,
  FulfillmentPut,
  FulfillmentRawLog,
  FulfillmentFormattedLog,
} from '@/types/fulfillmentType'
import type { EntityTypeOf } from '@/types/types'

export default class {
  static async getFulfillmentsByEntityAndId(
    entityType: EntityTypeOf,
    entityId: string,
    page: number,
  ) {
    return axios.get<FulfillmentType[]>('fulfillments', {
      params: { [entityType]: entityId, page: page },
    })
  }

  static async getFulfillmentById(id: string) {
    return axios.get<FulfillmentType>(`fulfillments/${id}`)
  }

  static async getRawLogByFulfillmentId(id: string) {
    return axios.get<FulfillmentRawLog>(`fulfillments/${id}/process_log`, {
      params: { raw: true },
    })
  }

  static async getFormattedLogByFulfillmentId(id: string) {
    return axios.get<FulfillmentFormattedLog>(`fulfillments/${id}/process_log`)
  }

  static async putFulfillment(fulfillment: FulfillmentPut) {
    return axios.put<FulfillmentType>(
      `fulfillments/${fulfillment.id}`,
      fulfillment,
    )
  }

  static async putFloatingFulfillment(
    floatingFulfillment: FloatingFulfillmentPut,
  ) {
    return axios.put<FulfillmentType>(
      `floating_status/${floatingFulfillment.id}`,
      floatingFulfillment,
    )
  }
}
