import { useUserStore } from '@/stores/users'
import { storeToRefs } from 'pinia'
import { RolePermission } from '@/types/roleType'

/**
 * Checks if a user is authorized based on required permissions.
 * You can enter a combination of RolePermissions and RolePermission arrays,
 * where everything in an array gets evaluated with OR, while a singular item will be evaluated with AND.
 *
 * @example
 * isAuthorized('user:read', 'supplier:read')                      // user:read AND supplier:read
 * isAuthorized(['user:read', 'supplier:read'])                    // user:read OR supplier:read
 * isAuthorized(['user:read', 'supplier:read'], 'supplier:write' ) // (user:read OR supplier:read) AND supplier:write
 *
 * @param permissions
 */
export const isAuthorized = (
  ...permissions: Array<
    | RolePermission
    | `${RolePermission}`
    | Array<RolePermission | `${RolePermission}`>
  >
) => {
  const userStore = useUserStore()
  const { me } = storeToRefs(userStore)

  return permissions.every((permission) =>
    Array.isArray(permission)
      ? permission.some((r) =>
        me.value.permissions.includes(r as RolePermission),
      )
      : me.value.permissions.includes(permission as RolePermission),
  )
}

/**
 * Checks if the user is a supplier.
 *
 * @returns {boolean} Returns `true` if the user is a supplier, otherwise `false`.
 */
export const isSupplier = (): boolean => {
  const userStore = useUserStore()
  const { me } = storeToRefs(userStore)

  return !!me.value.suppliers?.length
}

/**
 * Checks if the user belongs to a municipality.
 *
 * @returns {boolean} `true` if the user belongs to a municipality, `false` otherwise.
 */
export const isMunicipality = (): boolean => {
  const userStore = useUserStore()
  const { me } = storeToRefs(userStore)

  return !!me.value.municipalities?.length
}

/**
 * Determines if the given ID matches the ID of the currently logged-in user.
 *
 * @param {string} id - The ID to check against the logged-in user's ID.
 * @returns {boolean} - `true` if the given ID matches the logged-in user's ID, `false` otherwise.
 */
export const isMe = (id: string): boolean => {
  const userStore = useUserStore()
  const { me } = storeToRefs(userStore)

  return me.value.id === id
}
